<template>
  <div class="wrapper">
    <div class="background top">
      <div class="container">
        <b-row class="top">
          <p @click="activeTab = 'asesoramiento'" :class="[activeTab === 'asesoramiento' ? 'btn-active' : 'btn-inactive']">ASESORAMIENTO</p>
          <p @click="activeTab = 'recursos'" :class="[activeTab === 'recursos' ? 'btn-active' : 'btn-inactive']">RECURSOS PARA EL EMPLEO</p>
        </b-row>
        <div class="content">
          <div v-if="activeTab === 'asesoramiento'">
            <h2>{{ $t('empleabilidad.counseling') }}</h2>
            <p>
              Todos los asociados al club de selección de personal y de Prácticas en
              Empresas ofrece asesoramiento individualizado personas en búsqueda de
              empleo y alumnos actuales en área de posgrado o fin de carrera
              universitaria que quieran  mejorar en los aspectos vinculados al
              desarrollo de su carrera profesional (estrategia de búsqueda de empleo,
              revisión de los curriculum vitae, marca personal, etc…)
            </p>
          </div>
          <div v-if="activeTab === 'recursos'">
            <h2>RECURSOS DISPONIBLES</h2>
            <p>
              Te ofrecemos materiales que te ayudarán a mejorar tu nivel de empleabilidad.
            </p>
            <Accordion />
          </div>
        </div>
      </div>
    </div>
    <div class="background bot" v-if="activeTab === 'asesoramiento'">
      <div class="container">
        <b-row class="rowas reverse">
          <b-col cols="12" class="col-md-8 text">
             <h2>
              ¿Necesitas una 
              <br>
              <strong>CITA PARA ASESORARTE?</strong>
            </h2>
            <p>
              Por favor ponte en contacto con el equipo vía email o telefónicamente y
              programaremos un encuentro contigo para atenderte adecuadamente.
            </p>
            <div class="button brown">
              Solicitar una cita
              <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
            </div>
          </b-col>
          <b-col cols="12" class="col-md-4 img">
            <img loading="lazy" alt="icon" src="/images/work/asesoramiento1.png" />
          </b-col>
        </b-row>
        <b-row class="rowas">
          <b-col cols="12" class="col-md-5 img">
            <img loading="lazy" alt="icon" src="/images/work/asesoramiento2.png" />
          </b-col>
          <b-col cols="12" class="col-md-7 text">
            <h2>
              ¿Necesitas
              <br>
              <strong>COACHING PROFESIONAL</strong>
              <br>
              para mejorar?
            </h2>
            <p>
              Si tus necesidades de mejora requieren de un tratamiento mucho más profundo,
              el departamento de carreras profesionales colabora con un equipo de coachers
              profesionales que podrán hacer un diagnóstico de tu situación y ofrecerte un
              plan de sesiones para la consecución de esas mejoras. Este servicio exclusivo,
              está dirigido a personas con experiencia profesional y tiene un COSTE. Para
              profundizar sobre la naturaleza de este servicio, por favor contacta con el
              Director del Departamento.
            </p>
            <div class="button black">
              Más información
              <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
            </div>
          </b-col>
        </b-row>
        <b-row class="rowas reverse no-border">
          <b-col cols="12" class="col-md-8 text">
             <h2>
              ¿Necesitas un feed-back sobre
              <br>
              <strong>TU PERFIL PROFESIONAL EN LINKEDIN?</strong>
            </h2>
            <p>
              El departamento pone a tu disposición la posibilidad de analizar tu perfil
              de LinkedIn y ofrecerte asesoramiento y mejorar sobre el mismo. Entra en
              contacto con el equipo del departamento de Desarrollo Profesional y envíanos
              tu dirección de LinkedIn. En el transcurso de 4 días hábiles, te enviaremos
              nuestros consejos.
            </p>
            <div class="button blue">
              Quiero contactar
              <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
            </div>
          </b-col>
          <b-col cols="12" class="col-md-4 img">
            <img loading="lazy" alt="icon" src="/images/work/asesoramiento3.png" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from '../../components/team/Accordion';
export default {
  watch: {
    '$route.query.menu': function (newQuery, oldQuery) {
      this.activeTab = newQuery;
    }
  },
  data() {
    return {
      activeTab: 'asesoramiento'
    }
  },
  metaInfo() {
    return {
      title: this.$t('empleabilidad.counseling'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('empleabilidad.counseling') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('empleabilidad.counseling') + ' - Club de Ejecutivos' },
      ]
    }
  },
  components: {
    Accordion
  },
  mounted() {
    if(this.$route.query.menu) {
      this.activeTab = this.$route.query.menu;
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    text-align: left;
  }

  .background.top {
    background: #d3d5d6;
    padding: 50px 0;

    .content {
      padding-top: 25px;

      h2 {
        font-weight: 800;
        text-transform: uppercase;
      }
    }
  }
  .row.top {
    border-bottom: 1px solid #1a171b;
    margin: auto;
    
    p {
      text-transform: uppercase;
      color: white;
      font-weight: 800;
      font-size: 1.1rem;
      padding: 10px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-right: 15px;
      cursor: pointer;
      margin-bottom: 2px;
    }

    .btn-active {
      background: #009090;
    }

    .btn-inactive {
      background: #414552;
      transition: 0.2s ease;
    }

    .btn-inactive:hover {
      background: #009090;
    }
  }

  .bot {
    .rowas {
      border-bottom: 1px solid #1a171b;
      padding: 50px 0;

      .text {
        margin: auto 0;
      }

      .img {
        text-align: center;
      }

      h2 {
        font-size: 1.5rem;
        width: 50%;
        font-weight: 300;

        strong {
          font-weight: 800;
        }
      }

      .button {
        text-transform: uppercase;
        color: white;
        cursor: pointer;
        padding: 10px 20px;
        font-size: 1.2rem;
        font-weight: 800;
        display: unset;
        margin: 10px 0;

        &.brown {
          background: #664837;
          float: left;
        }

        &.black {
          background: #151d20;
          float: right;
        }

        &.blue {
          background: #1980b4;
          float: left;
        }
      }

      .button:hover {
        opacity: 0.7;
      }

       
    }

    .no-border {
      border: none;
    }
  }

  @media screen and (max-width: 767px) {
    .rowas.reverse {
      flex-direction: column-reverse;
    }

    .rowas {
      h2 {
        width: 100% !important;
      }

      .text {
        margin-top: 15px !important;
      }

      .button {
        width: 100%;
      }
    }
  }
</style>